import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostTags,
} from './templates.style';

const BlogPostTemplate = (props: any) => {
  const post = props.data.singleBlogPost.edges[0].node;
  const { edges } = props.data.relatedBlogPost;
  const { siteUrl, defaultTitle } = props.data.site.siteMetadata;
  const shareUrl = siteUrl + post.slug;

  return (
    <Layout>
      <SEO
        title={post.title + ' | ' + defaultTitle}
        description={post.seoDescription}
        banner={post.featured ? post.featured.fluid.src : ''}
        article
        pathname={shareUrl}
      />
      <BlogPostDetailsWrapper>
        <PostDetails
          title={post.title}
          date={post.createdAt}
          preview={post.featured == null ? null : post.featured.fluid}
          description={post.content.content}
        />

        <BlogPostFooter>
          {post.tags == null ? null : (
            <PostTags className="post_tags">
              {post.tags.map((tag: any, index: number) => (
                <Link key={index} to={`/tags/${tag.slug}/`}>
                  {`#${tag.name}`}
                </Link>
              ))}
            </PostTags>
          )}
        </BlogPostFooter>
      </BlogPostDetailsWrapper>

      {edges && edges.length > 0 ? (
        <RelatedPostWrapper>
          <RelatedPostTitle>บทความที่เกี่ยวข้อง</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.slug}>
                <PostCard
                  title={node.title || node.slug}
                  url={`/${node.slug}`}
                  image={node.featured == null ? null : node.featured.fluid}
                  tags={node.tags}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      ) : null}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    singleBlogPost: allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          seoDescription
          slug
          title
          createdAt(formatString: "DD MMM, YYYY")
          tags {
            slug
            name
          }
          featured {
            fluid(maxWidth: 1170, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
              src
            }
          }
          content {
            content
          }
        }
      }
    }
    relatedBlogPost: allContentfulBlogPost(
      limit: 3
      sort: { fields: createdAt, order: DESC }
      filter: {
        tags: { elemMatch: { slug: { in: $tag } } }
        slug: { ne: $slug }
      }
    ) {
      edges {
        node {
          slug
          title
          tags {
            slug
            name
          }
          featured {
            fluid(maxWidth: 370, maxHeight: 220, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(width: 370, height: 220) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        defaultTitle
      }
    }
  }
`;
