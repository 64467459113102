import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  PostDescription,
  PostTags,
} from './post-details.style';

type PostDetailsProps = {
  title: string;
  date?: string;
  preview?: any;
  description: any;
  tags?: [];
  className?: string;
  imagePosition?: 'top';
};

const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
  title,
  date,
  preview,
  description,
  tags,
  className,
  imagePosition,
  ...props
}) => {
  const addClass: string[] = ['post_details'];

  if (className) {
    addClass.push(className);
  }

  return (
    <PostDetailsWrapper {...props} className={addClass.join(' ')}>
      <PostTitle>{title}</PostTitle>
      <PostDate>{date}</PostDate>
      {preview == null ? null : (
        <PostPreview className="post_preview">
          <Img fluid={preview} alt={title} />
        </PostPreview>
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        <PostDescription className="post_des">
          <ReactMarkdown remarkPlugins={[gfm]} children={description} />
        </PostDescription>
        {tags == null ? null : (
          <PostTags>
            {tags.map((tag: any, index: number) => (
              <Link key={index} to={`/tags/${tag.slug}/`}>
                {`#${tag.title}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  );
};

PostDetails.defaultProps = {
  imagePosition: 'top',
};

export default PostDetails;
